<template>
  <ion-header class="d-flex align-center custom-header">
    <ion-toolbar class="custom-toolbar">
      <ion-grid>
        <ion-row class="align-center" id="row">
          <ion-col size="9" class="d-flex">
            <ion-label class="fs-4 fw-bold">{{ $t('account') }}</ion-label>
          </ion-col>
          <ion-col size="3" class="header-right d-flex ion-justify-content-around align-center">
            <div class="right-header">
              <notification-badge />
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import NotificationBadge from '@/modules/shared/components/notifications/NotificationBadge.vue';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('sale/account');

export default defineComponent({
  name: 'AccountHeader',
  components: {
    // User defined component
    NotificationBadge
  },

  computed: {
    ...mapGetters(['user'])
  }
});
</script>

<style scoped>
.custom-header.header-md::after {
  background-image: none;
}
.custom-toolbar {
  --border-width: 0px !important;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  font-size: 1.1rem;
  height: 56px;
  font-weight: bold;
}

.left-header {
  font-size: 16px;
  font-weight: bold;
}

.right-header {
  margin-left: auto;
}
.header-icon {
  color: var(--ion-color-primary);
}
#row {
  height: 50px;
}
</style>
