<template>
  <ion-page>
    <account-header />
    <skeleton-account v-if="isLoading" />
    <ion-content id="content" v-else>
      <account-info :key="reRenderUserInfo" :userData="userData" />
      <card-invoice-account @onTopUpCreditToggle="setShowTopUpCredit(true)" />
      <extra-info />
      <div class="spacer"></div>
    </ion-content>
    <ion-modal
      mode="ios"
      :initial-breakpoint="0.7"
      :is-open="isShowTopUpCredit"
      :backdropDismiss="true"
      @didDismiss="setShowTopUpCredit(false)"
      class="modal-radius-md"
    >
      <modal-top-up-credit
        ref="modalTopUpRef"
        @onTopUpApplied="onTopUpApplied"
        @closeModal="setShowTopUpCredit(false)"
      />
    </ion-modal>
  </ion-page>
</template>
<script>
import { ACTIONS } from '@/modules/sale/store/account/actions';
import { ACTIONS as ACTIONSPAYMENT } from '@/modules/sale/store/payment/actions';
import SkeletonAccount from '@/modules/shared/components/accounts/SkeletonAccount.vue';
import { ModalTopUpCredit } from '@/modules/shared/components/invoices-payment';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AccountHeader from './components/AccountHeader.vue';
import AccountInfo from './components/AccountInfo.vue';
import CardInvoiceAccount from './components/CardInvoiceAccount.vue';
import ExtraInfo from './components/ExtraInfo.vue';

export default defineComponent({
  name: 'Account',
  components: {
    AccountHeader,
    AccountInfo,
    ExtraInfo,
    CardInvoiceAccount,
    ModalTopUpCredit,
    SkeletonAccount
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(false);
    const userData = ref(null);
    const modalTopUpRef = ref(null);
    const isShowTopUpCredit = ref(false);
    const reRenderUserInfo = ref(1);
    const setLoading = (state) => (isLoading.value = state);
    const currentActiveUser = computed(() => store.getters[`sale/account/user`]);
    const setShowTopUpCredit = (state) => {
      isShowTopUpCredit.value = state;
      if (isShowTopUpCredit.value) {
        setTimeout(() => {
          modalTopUpRef.value.loadFocus();
        }, 500);
      }
    };
    const onTopUpApplied = async (paramsTopUpCredit) => {
      setShowTopUpCredit(false);
      await store.dispatch(`sale/payment/${ACTIONSPAYMENT.SET_PARAMS_TOP_UP_CREDIT}`, {
        paramsTopUpCredit
      });
      router.push('/sale/invoices/select-payment');
    };
    onMounted(async () => {
      setLoading(true);
      await store.dispatch(`sale/account/${ACTIONS.GET_CURRENT_USER}`);
      userData.value = currentActiveUser.value;
      setLoading(false);
    });
    watch(currentActiveUser, (updatedUserData) => {
      userData.value = updatedUserData;
      reRenderUserInfo.value++;
    });
    return {
      userData,
      isLoading,
      modalTopUpRef,
      isShowTopUpCredit,
      onTopUpApplied,
      setShowTopUpCredit,
      reRenderUserInfo
    };
  }
});
</script>
<style lang="scss" scoped></style>
