<template>
  <ion-header mode="md" class="custom-header">
    <ion-toolbar class="custom-toolbar">
      <ion-title class="fs-3 text-center fw-bold">{{ $t('contact_us') }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content scroll-y="false">
    <ion-grid>
      <ion-row v-if="userData?.country?.id !== 96">
        <ion-col
          v-for="item in socialInfosGeneral"
          :key="item.name"
          size="4"
          class="d-flex flex-column align-center"
        >
          <a :href="item.shareLink" target="_blank" rel="noopener noreferrer">
            <ion-img :src="item.logoUrl" class="social-img" :alt="item.altImg" />
          </a>
          <ion-text>
            <div class="mt-2 fw-bold">{{ item.name }}</div>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row v-else>
        <ion-col
          v-for="item in socialInfosIdn"
          :key="item.name"
          size="4"
          class="d-flex flex-column align-center"
        >
          <a :href="item.shareLink" target="_blank" rel="noopener noreferrer">
            <ion-img :src="item.logoUrl" class="social-img" :alt="item.altImg" />
          </a>
          <ion-text>
            <div class="mt-2 fw-bold">{{ item.name }}</div>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="mx-2">
        <ion-text>
          <h6>{{ $t('contact_p1') }}</h6>
          <p>
            {{ $t('contact_p2') }}<br />
            {{ $t('contact_p3') }}
          </p>
          <p>
            {{ $t('contact_p4') }}<br />
            {{ $t('contact_p5') }}<br />
            {{ $t('contact_p6') }} <br />
            {{ $t('contact_p7') }}
          </p>
        </ion-text>
      </ion-row>
    </ion-grid>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContactUs',
  inject: ['$storage'],
  data() {
    return {
      socialInfosGeneral: [
        {
          name: this.$t('wa'),
          shareLink: 'https://wa.me/6588353386',
          logoUrl: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/whatsapp-256.png',
          altImg: this.$t('wa_alt_img')
        },
        {
          name: this.$t('phone'),
          shareLink: 'tel:+65 3138 5464',
          logoUrl: 'https://cdn2.iconfinder.com/data/icons/circle-icons-1/64/phone-256.png',
          altImg: this.$t('phone_alt_img')
        },
        {
          name: this.$t('email'),
          shareLink: 'mailto:hello@thetreedots.com',
          logoUrl: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/mail-256.png',
          altImg: this.$t('email_alt_img')
        }
      ],
      socialInfosIdn: [
        {
          name: this.$t('wa'),
          shareLink: 'https://wa.me/6282110001778',
          logoUrl: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/whatsapp-256.png',
          altImg: this.$t('wa_alt_img')
        },
        {
          name: this.$t('phone'),
          shareLink: 'tel:+65 3138 5464',
          logoUrl: 'https://cdn2.iconfinder.com/data/icons/circle-icons-1/64/phone-256.png',
          altImg: this.$t('phone_alt_img')
        },
        {
          name: this.$t('email'),
          shareLink: 'mailto:hello.id@thetreedots.id',
          logoUrl: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/mail-256.png',
          altImg: this.$t('email_alt_img')
        }
      ],
      userData: null
    };
  },
  async mounted() {
    this.userData = await this.$storage.getUser();
  }
});
</script>

<style scoped>
.custom-header.header-md::after {
  background-image: none;
}
.custom-toolbar {
  padding: 8px 16px;
  --border-width: 0px !important;
}
.social-img {
  width: 56px;
  height: 56px;
}
h6 {
  color: #667085;
  font-size: 12px;
  font-weight: 800;
}
ion-text {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
}
</style>
