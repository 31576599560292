<template>
  <div class="px-5 py-5 text-center">
    <div class="fs-4 fw-normal mt-2">{{ title }}</div>
    <div class="py-2 fs-3 my-3 alert-msg">{{ content }}</div>
    <ion-button class="mb-2" size="small" color="primary" expand="full" @click="$emit('confirm-yes-action')">
      {{ $t('yes') }}
    </ion-button>
    <ion-button size="small" color="grey2" expand="full" @click="$emit('confirm-no-action')">
      {{ $t('no') }}
    </ion-button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalContentConfirm',
  props: ['title', 'content'],
  emits: ['confirm-yes-action', 'confirm-no-action']
});
</script>
<style scoped>
.alert-msg {
  line-height: 1.5;
}
</style>
